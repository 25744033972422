<template>
  <div find-id-type class="contents">
    <div class="content">
      <FpTitle title="findId.title.findGGPassId" />
      <p class="desc" v-html="$t('findId.description.selectDesc', {brand : displayBrandName})" />
      <div fi-select class="field-wrapper">
        <FiSelectButton :label="$t('findId.label.mobileNumberVerification')" @click="mobileClick" />
        <FiSelectButton :label="$t('findId.label.emailVerification')" @click="emailClick" />
        <FiSelectButton :label="$t('findId.label.loginWithAccount' , {brand : displayBrandName})" @click="loginClick" />
      </div>
    </div>
  </div>
</template>

<script>
import FiSelectButton from '@/views/components/pages/find-id/FiSelectButton.vue';
import ProgressButton from '@/views/components/common/ProgressButton.vue';
import FpTitle from '@/views/components/pages/forgot-password/FpTitle.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { brandIds, displayBrandNames } from '@/constants/base/siteMap';
export default {
  name: 'FindIdType',
  components: { FpTitle, ProgressButton, FiSelectButton },
  computed: {
    brand: state('env', 'brand'),
    displayBrandName() {
      const key = Object.keys(brandIds).find(key => brandIds[key] === this.brand);
      return this.brandNames[key];
    },
    brandNames(){
      return displayBrandNames
    }
  },
  methods: {
    mobileClick() {
      console.log('click');
      this.routeName('FiMobile');
    },
    emailClick() {
      this.routeName('FiEmail');
    },
    loginClick() {
      this.routeName('FiCredential');
    }
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[find-id-type] {
  .field-wrapper { .flex(); .flex-dc(); .gap(10); }
}
</style>